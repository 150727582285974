import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import WebComponent from 'utils/web-component';
import styles from './LogoLoader.module.scss';

const LogoLoader = () => (
  <div className={styles.wrap}>
    <LoadingIndicator infinite />
    <div className={styles.logo}>
      <WebComponent tag="fl-logo" css="max-width: 100%; width: 300px;" transparent="true" />
    </div>
  </div>
);

export default LogoLoader;
