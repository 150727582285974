import React from 'react';
import PropTypes from 'prop-types';

import SecondaryActionButton from 'components/SecondaryActionButton';
import styles from './UserMessageResponseDropdown.module.scss';
import WebComponent from 'utils/web-component';

const CheckMarkIcon = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={styles.userMessageResponseCheckmark}
  >
    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
  </svg>
));

const UserMessageResponseDropdown = ({
  disableUndo,
  options,
  onChange,
  selectedOption,
  undoText,
  undoAction,
  customUndoAction,
}) => {
  return (
    <React.Fragment>
      <div className={styles.userMessageResponse}>
        <WebComponent tag='fl-button'>
          <select
            value={selectedOption.value}
            onChange={e => onChange(e.target.value)}
            className={styles.select}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {selectedOption.text}
          <span className={styles.dropdownIcon} />
          <CheckMarkIcon />
        </WebComponent>
      </div>
      {!disableUndo && (
        <SecondaryActionButton onClick={undoAction}>{undoText}</SecondaryActionButton>
      )}

      {customUndoAction && <>{customUndoAction}</>}
    </React.Fragment>
  );
};

UserMessageResponseDropdown.defaultProps = {
  disableUndo: false,
  undoText: 'Change my answer…',
};

UserMessageResponseDropdown.propTypes = {
  undoText: PropTypes.string,
  undoAction: PropTypes.func.isRequired,
};

export default React.memo(UserMessageResponseDropdown);
