// # Site concept
import { createSelector } from 'reselect';
import get from 'lodash/get';
import getOr from 'lodash/fp/getOr';
import toString from 'lodash/fp/toString';
import { navigate } from '@reach/router';

import { showError } from 'concepts/error';
import * as api from 'services/api';
import redirect from 'services/redirect';
import { getFlocklerSiteUrl } from 'services/flockler-urls';
import { FEED_TYPES } from 'constants/Services';

// Action types
const ACTIONS = Object.freeze({
  SET_SECTION_ID: 'SITE/SET_SECTION_ID',
  SITE_LOADED: 'SITE/SITE_LOADED',
  SITE_SETTINGS_LOADED: 'SITE/SITE_SETTINGS_LOADED',
  SITE_SUBSCRIPTION_LOADED: 'SITE/SITE_SUBSCRIPTION_LOADED',
});

const ERROR_TYPES = Object.freeze({
  NO_SITE_URL: 'NO_SITE_URL',
});

// Selectors
export const getRouteParamSectionId = state => state.site.sectionId;
export const getSite = state => state.site.site;
export const getSiteSettings = state => state.site.siteSettings;
export const getSiteSubscription = state => state.site.siteSubscription;

export const getEnabledFeedTypes = createSelector(getSiteSettings, settings => ({
  // API does not return these, by default ON
  [FEED_TYPES.INSTAGRAM]: true,
  [FEED_TYPES.TWITTER]: true,

  // Enabled for all, NOT respecting `linkedin_media_tracker_enabled` setting
  [FEED_TYPES.LINKEDIN]: true,

  // Are media tracker(s) enabled?
  [FEED_TYPES.FACEBOOK]: get(settings, 'facebook_media_trackers_enabled', false),
  [FEED_TYPES.PINTEREST]: get(settings, 'pinterest_media_tracker_enabled', false),
  [FEED_TYPES.RSS]: get(settings, 'rss_media_tracker_enabled', false),
  [FEED_TYPES.TIKTOK]: get(settings, 'tiktok_media_tracker_enabled', false),
  [FEED_TYPES.YOUTUBE]: get(settings, 'youtube_media_tracker_enabled', false),
  [FEED_TYPES.REVIEWS]: get(settings, 'reviews_enabled', false),
  [FEED_TYPES.GOOGLE_REVIEW]: get(settings, 'google_reviews_media_tracker_enabled', false),
}));

// lodash-fp getOr syntax is getOr(defaultValue, path)(object)
export const getSiteSections = createSelector(getSite, getOr([], 'sections'));

// Get Section id
// If route contains section id, we return that (check if correct)
// Else returns id for FIRST section, no logic how to choose another
export const getSectionId = createSelector(
  getSiteSections,
  getRouteParamSectionId,
  (siteSections, routeSectionId) => {
    const isRouteSectionIdValid =
      siteSections.findIndex(s => toString(s.id) === toString(routeSectionId)) >= 0;

    return isRouteSectionIdValid ? routeSectionId : get(siteSections, [0, 'id'], null);
  }
);

export const getSiteId = createSelector(getSite, getOr(null, 'id'));

export const getSiteUrl = createSelector(getSite, getOr(null, 'site_url'));

export const getSiteArticlesCount = createSelector(getSite, getOr(0, 'articles_count'));

export const getSiteCmsType = createSelector(getSiteSettings, getOr(null, 'cms_type'));

export const getSiteMaxFeeds = createSelector(getSiteSettings, getOr(null, 'max_media_trackers'));

export const getSiteAiModerationEnabled = createSelector(getSiteSettings, getOr(null, 'ai_moderation_enabled'));

export const getSiteSubscriptionPlan = createSelector(getSiteSubscription, getOr(null, 'plan'));

export const getSiteSubscriptionCustomPlan = createSelector(getSiteSubscription, getOr(null, 'custom_plan_name'));

// Action creators
export const loadSiteAction = site => ({ type: ACTIONS.SITE_LOADED, payload: site });
export const loadSiteSubscriptionAction = subscription => ({ type: ACTIONS.SITE_SUBSCRIPTION_LOADED, payload: subscription });
export const loadSiteSettingsAction = settings => ({ type: ACTIONS.SITE_SETTINGS_LOADED, payload: settings });

export const reloadSite = () => (dispatch, getState) => {
  const siteId = getSiteId(getState());
  return api.fetchSite(siteId).then(response => dispatch(loadSiteAction(response.data)));
};

export const reloadSiteSettings = () => (dispatch, getState) => {
  const siteId = getSiteId(getState());
  return Promise.all([
    api.fetchSiteSettings(siteId),
    api.fetchSiteSubscription(siteId),
  ]).then(([siteSettingsResponse, subscriptionResponse]) => {
      const { settings } = siteSettingsResponse.data;
      const { subscription } = subscriptionResponse.data;

      dispatch(loadSiteSettingsAction(settings));
      dispatch(loadSiteSubscriptionAction(subscription));
    });
};

export const fetchSite = siteUrl => (dispatch, getState) => {
  let site;
  return api
    .fetchSites({ site_url: siteUrl })
    .then(({ data }) => {
      site = data;
      const siteId = site.id;

      // Set site id, name, and url to window so that analytics event can grab those
      window._flocklerSiteId = siteId;
      window._flocklerSiteName = site.name;
      window._flocklerSiteUrl = site.site_url;

      // Site section in route has to be valid
      // redirect (navigate) to correct sectionId route
      const sectionId = getRouteParamSectionId(getState());
      const sectionIdIndex = site.sections.findIndex(s => toString(s.id) === toString(sectionId));
      if (sectionId && sectionIdIndex < 0) {
        navigate(`/${siteUrl}/walls/${site.sections[0].id}`);
      }

      // Fetch settings & subscription for site
      return Promise.all([api.fetchSiteSettings(siteId), api.fetchSiteSubscription(siteId)]);
    })
    .then(([siteSettingsResponse, subscriptionResponse]) => {
      const { settings } = siteSettingsResponse.data;
      const { subscription } = subscriptionResponse.data;

      // Check if site has access to feeds.
      // A) site subscription is invalid (use explicit "false" here since it's new property in API)
      // B) site has no media trackers enabled
      const isSubscriptionInvalid = subscription.enabled === false;
      const isMediaTrackersDisabled = !settings.media_trackers_enabled;

      if (isMediaTrackersDisabled || isSubscriptionInvalid) {
        const url = getFlocklerSiteUrl(settings.cms_type, { siteUrl });
        redirect(url);
        return;
      }

      // Save site, site settings, and subscription to store
      return Promise.all([
        dispatch(loadSiteAction(site)),
        dispatch(loadSiteSettingsAction(settings)),
        dispatch(loadSiteSubscriptionAction(subscription)),
      ]);
    })
    .catch(error => {
      let errorMessage = `Site ${siteUrl} was not found`;
      dispatch(showError(errorMessage));
    });
};

export const initializeDefaultSite = () => dispatch => {
  return api
    .fetchSites({ fields: 'id,site_url' })
    .then(({ data }) => {
      const site = data[0] || {};

      if (!site.site_url) {
        return Promise.reject(ERROR_TYPES.NO_SITE_URL);
      }

      navigate(`/${site.site_url}`);
      return Promise.resolve();
    })
    .catch(error => {
      let errorMessage = 'No site found for your account';

      if (error === ERROR_TYPES.NO_SITE_URL) {
        errorMessage = 'No site url found for site';
      }

      dispatch(showError(errorMessage));
    });
};

export const setSectionId = sectionId => (dispatch, getState) => {
  const state = getState();
  const siteSections = getSiteSections(state);
  const siteUrl = getSiteUrl(state);

  // If site does not have the route param section, redirect to first correct one
  const sectionIdIndex = siteSections.findIndex(s => toString(s.id) === toString(sectionId));
  if (sectionId && sectionIdIndex < 0 && siteSections.length) {
    navigate(`/${siteUrl}/walls/${siteSections[0].id}`);
    return;
  }

  return dispatch({
    type: ACTIONS.SET_SECTION_ID,
    payload: sectionId,
  });
};

// Reducer
const initialState = Object.freeze({
  sectionId: null,
  site: null,
  siteSettings: null,
  siteSubscription: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_SECTION_ID: {
      return { ...state, sectionId: action.payload };
    }

    case ACTIONS.SITE_LOADED: {
      return { ...state, site: action.payload };
    }

    case ACTIONS.SITE_SETTINGS_LOADED: {
      return { ...state, siteSettings: action.payload };
    }

    case ACTIONS.SITE_SUBSCRIPTION_LOADED: {
      return { ...state, siteSubscription: action.payload };
    }

    default:
      return state;
  }
}
