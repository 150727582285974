import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import castArray from 'lodash/castArray';
import WebComponent from 'utils/web-component';

import SecondaryActionButton from 'components/SecondaryActionButton';
import styles from './UserMessageResponse.module.scss';

const CheckMarkIcon = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={styles.userMessageResponseCheckmark}
  >
    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
  </svg>
));

const UserMessageResponse = ({
  undoText,
  undoAction,
  customUndoAction,
  disableUndo,
  response,
  image,
  isTags,
  heading,
}) => {
  const responseValues = castArray(response);

  return (
    <React.Fragment>
      {heading && (
        <div>
          <WebComponent tag="fl-button" variant="success" fakebutton="true">
            {heading} <CheckMarkIcon />
          </WebComponent>
        </div>
      )}
      <div
        className={classnames({
          [styles.tagResponses]: isTags,
        })}
      >
        {responseValues.map(responseValue => (
          <WebComponent tag="fl-button" variant="success"
            fakebutton="true"
            key={responseValue}
            state="inactive"
            class={`ml ${!!image ? 'image' : ''}`}
          >
            {!!image && <img src={image} className={styles.responseImage} alt="" />}
            {responseValue}
            {!isTags && <CheckMarkIcon />}
          </WebComponent>
        ))}
      </div>

      {!disableUndo && (
        <SecondaryActionButton onClick={undoAction}>{undoText}</SecondaryActionButton>
      )}

      {customUndoAction && <>{customUndoAction}</>}
    </React.Fragment>
  );
};

UserMessageResponse.defaultProps = {
  children: undefined,
  disableUndo: false,
  undoText: 'Change my answer…',
  customUndoActions: undefined,
};

UserMessageResponse.propTypes = {
  children: PropTypes.any,
  disableUndo: PropTypes.bool,
  undoText: PropTypes.string,
  undoAction: PropTypes.func.isRequired,
};

export default React.memo(UserMessageResponse);
