import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import LoadingIndicator from 'components/LoadingIndicator';
import validUrl from 'valid-url';

import { helpScoutMessage } from 'utils/help-scout';
import WebComponent from 'utils/web-component';
import PreviewItem from 'components/PreviewItem';
import UserMessageActionCard from 'components/UserMessageActionCard';
import { validateFeedUrl, feedPreview } from 'services/api';
import { ReactComponent as ExternalLinkSVG } from 'assets/svgs/icon-link.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/icon-search.svg';
import styles from './RssLinkInput.module.scss';

// From preview DTO to PreviewItem view format
const fromDto = ({ author, image_url, link, published, summary, title }) => ({
  author,
  body: summary,
  cover_url: image_url,
  published_at: published,
  title,
  url: link,
});

const rssValidatorWarningLabels = Object.freeze({
  title_missing: 'Title is missing.',
  publish_time_missing: 'Publish time is missing.',
  text_content_missing: 'Text content is missing.',
  link_missing: 'There is no link.',
  images_missing: 'There are no images in content.',
});

class PinterestLinkInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',

      isInvalidUrl: false,
      isCheckingUrl: false,
      isPreviewMode: false,

      urlErrors: [],
      urlWarnings: [],

      previewTitle: '',
      previewItems: [],
    };
  }

  handleInputChange = e => {
    const inputText = e.target.value;
    this.setState({
      inputText,

      isPreviewMode: false,

      isInvalidUrl: false,
      isCheckingUrl: false,
    });
  };

  cancelPreview = () => {
    this.setState({
      isPreviewMode: false,
      inputText: '',

      isInvalidUrl: false,
      isCheckingUrl: false,
    });
  };

  checkUrl = e => {
    e.preventDefault();

    const { inputText } = this.state;

    if (validUrl.isUri(inputText)) {
      this.checkRssUrl(inputText);
    }
  };

  checkRssUrl = url => {
    // Clear state
    this.setState({
      isPreviewMode: false,
      isCheckingUrl: true,
      urlErrors: [],
      urlWarnings: [],
      previewItems: [],
    });

    // # Validate Url
    return validateFeedUrl(url)
      .then(response => {
        const warnings = get(response, ['data', 'warnings'], []);
        const errors = get(response, ['data', 'errors'], []);
        const isValidUrl = errors.length === 0;

        this.setState({ urlErrors: errors, urlWarnings: warnings });

        if (isValidUrl) {
          return true;
        }

        this.setState({ isCheckingUrl: false, isInvalidUrl: true });
        return Promise.reject();
      })
      .then(() => feedPreview(url, 2))
      .then(response => {
        this.setState({
          isPreviewMode: true,
          isCheckingUrl: false,
          previewItems: get(response, ['data', 'items'], []),
          previewTitle: get(response, ['data', 'title'], []),
        });
      })

      .catch(() => this.setState({ isInvalidUrl: true, isCheckingUrl: false }));
  };

  renderPreviewMessage = () => {
    const { previewItems } = this.state;
    if (previewItems.length === 0) {
      return (
        <div className={styles.subTitle}>
          We couldn't get any content from the URL. You can create feed anyway, try with another RSS
          URL, or{' '}
          <span
            className={styles.link}
            onClick={() => helpScoutMessage({ subject: 'I have problems with RSS content.' })}
          >
            contact us
          </span>
          .
        </div>
      );
    }

    return (
      <div className={styles.subTitle}>
        This is a preview of the content we can get from the URL. If the content doesn’t look
        suitable for your feed, try with another RSS source URL or{' '}
        <span
          className={styles.link}
          onClick={() => helpScoutMessage({ subject: 'I have problems with RSS content.' })}
        >
          contact us
        </span>
        .
      </div>
    );
  };

  renderErrorMessage = () => (
    <span className={styles.inputValidationError}>Please enter a valid RSS URL</span>
  );

  render() {
    const {
      inputText,
      isCheckingUrl,
      isInvalidUrl,
      isPreviewMode,
      previewItems,
      previewTitle,
      urlWarnings,
    } = this.state;
    const { action, ...rest } = this.props;

    // check validity and parse parts from url
    const url = inputText; // todo validate UI first

    const isLinkValid = validUrl.isUri(url);
    const isUrlErrorMessageVisible = url.length > 0 && !isLinkValid;

    return (
      <UserMessageActionCard
        title={
          <span>
            Add the URL of RSS
            {isUrlErrorMessageVisible ? this.renderErrorMessage() : ''}
          </span>
        }
      >
        <form onSubmit={e => this.checkUrl(e)}>
          <div className={styles.wrapper}>
            <ExternalLinkSVG
              className={classnames(styles.linkIcon, { [styles.valid]: isLinkValid })}
            />
            <input
              autoFocus
              type="text"
              className={styles.input}
              onChange={this.handleInputChange}
              value={inputText}
              {...rest}
            />

            {isLinkValid && (
              <button className={styles.searchButton} onClick={this.checkUrl} type="button">
                <SearchIcon />
              </button>
            )}
          </div>
        </form>

        {url && isInvalidUrl && (
          <span className={classnames(styles.footer, styles.submitErrorMessage)}>
            Invalid RSS URL! Please check the URL.
          </span>
        )}

        {isCheckingUrl && (
          <span className={classnames(styles.footer, styles.loading)}>
            <span className={styles.loadingLabel}>Creating a preview of the feed</span>{' '}
            <LoadingIndicator />
          </span>
        )}

        {isPreviewMode && (
          <>
            {this.renderPreviewMessage()}
            <div className={styles.previewItems}>
              {previewItems.map(previewItem => (
                <PreviewItem {...fromDto(previewItem)} />
              ))}
            </div>

            {urlWarnings && urlWarnings.length > 0 && (
              <>
                <div className={styles.previewTitle}>
                  Possible issue{urlWarnings.length > 1 ? 's' : ''} with RSS
                </div>
                <ul className={styles.rssWarnings}>
                  {urlWarnings.map(warning => (
                    <li>{rssValidatorWarningLabels[warning] || warning}</li>
                  ))}
                </ul>
              </>
            )}

            <div className={styles.footer}>
              <WebComponent
                tag="fl-button"
                class="ml mt"
                variant="secondary"
                onClick={this.cancelPreview}
              >
                Cancel
              </WebComponent>

              <WebComponent
                tag="fl-button"
                class="ml mt"
                onClick={() => action({ url, name: previewTitle })}
                title={`Create feed with RSS URL ${url}`}
              >
                Create RSS Feed
              </WebComponent>
            </div>
          </>
        )}
      </UserMessageActionCard>
    );
  }
}

PinterestLinkInput.propTypes = {
  action: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PinterestLinkInput;
