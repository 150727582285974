import React, { useRef, useEffect } from 'react';

const WebComponent = ({ tag: Tag, children, disabled, ...props }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    if (typeof window !== 'undefined') {
      import('@flockler/fl-assets/index.js');
    }

    if (element) {
      Object.keys(props).forEach(key => {
        if (key.startsWith('on') && typeof props[key] === 'function') {
          // do nothing, allow host handle clicks
        } else if (key !== 'children') {
          if (key === 'disabled' && ![true, 'true', 1, '1', 'disabled'].includes(props[key])) {
            // Handle the 'disabled' attribute separately, as _any_ disabled value would mean disabled
            element.removeAttribute('disabled');
          } else {
            element.setAttribute(key, props[key]);
          }
        }
      });

      // Handle the 'disabled' attribute separately, as _any_ disabled value would mean disabled
      if (disabled) {
        element.setAttribute('disabled', 'true');
      } else {
        element.removeAttribute('disabled');
      }

      return () => {
        Object.keys(props).forEach(key => {
          if (key.startsWith('on') && typeof props[key] === 'function') {
            const eventName = key.slice(2).toLowerCase();
            element.removeEventListener(eventName, props[key]);
          }
        });
      };
    }
  }, [props, disabled]);

  return (
    <Tag ref={elementRef} {...props}>
      {children}
    </Tag>
  );
};

export default WebComponent;
