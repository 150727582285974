import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';

import {
  answerQuestion,
  createMediaTracker,
  createNewFeed,
  createHistoryPreview,
  getAnswers,
  getChannelConversationStatus,
  getConversationFeedType,
  getConversationRefreshStatus,
  getCurrentFeedCount,
  getFeedConfig,
  getFeedCreationConversationStatus,
  getFeedSentStatus,
  getHistoryPreviewLoadingState,
  getMediaTrackerCreationStatus,
  getMediaTrackersLimitReached,
  getPreviewItemsCount,
  getRouteParamSectionId,
  getQuestionAnswer,
  getSectionId,
  getSelectedFeedTypeName,
  getSessionCreatedFeedsCount,
  getSiteMaxMediaTrackers,
  getSiteSections,
  getSiteUrl,
  loadConversationState,
  setAccountId,
  revertToQuestion,
  setChannelConversationStatus,
  setFeedType,
} from 'concepts/feed-builder';
import { getFeedOwnAccountInfo, getFeedAccountName } from 'concepts/feed-inspector';
import { getMessengerOpenState } from 'concepts/messenger';
import { getUserId, getUserHasCreatedFirstFeed, getUserFirstname } from 'concepts/user';
import { getEnabledFeedTypes, getSiteAiModerationEnabled, getSiteId, getSiteSubscriptionPlan, getSiteSubscriptionCustomPlan, reloadSiteSettings } from 'concepts/site';

import {
  redirectToFlocklerAfterSuccessfulFeedCreation,
  redirectToFlocklerSite,
  redirectToFlocklerSection,
  redirectToFlocklerInbox,
  redirectToFlocklerFeedsList,
  redirectToFlocklerManualContent,
  redirectToFlocklerSubscription,
  redirectToFlocklerBlog,
} from 'concepts/redirect-flockler';
import { createSection, getSectionCreationStatus } from 'concepts/section';

import { getConversationState } from 'services/conversation-updater';
import { STEPS } from 'services/media-trackers/common';
import {
  QUESTION_IDS as INSTAGRAM_STEPS,
  SOURCES as INSTAGRAMS_SOURCES,
} from 'services/media-trackers/instagram';
import { QUESTION_IDS as LINKEDIN_STEPS } from 'services/media-trackers/linkedin';
import { FEED_TYPES, SERVICES } from 'constants/Services';
import { findById } from 'utils/common';
import ConversationAboutFacebook from 'components/ConversationAboutFacebook';
import ConversationAboutInstagram from 'components/ConversationAboutInstagram';
import ConversationAboutLinkedin from 'components/ConversationAboutLinkedin';
import ConversationAboutPinterest from 'components/ConversationAboutPinterest';
import ConversationAboutTiktok from 'components/ConversationAboutTiktok';
import ConversationAboutTwitterV2 from 'components/ConversationAboutTwitterV2';
import ConversationAboutYoutube from 'components/ConversationAboutYoutube';
import ConversationAboutReviews from 'components/ConversationAboutReviews';
import ConversationAboutGoogleReview from 'components/ConversationAboutGoogleReview';
import ConversationAboutRSS from 'components/ConversationAboutRSS';
import Question from 'components/Question';
import AnimatedDots from 'components/AnimatedDots';
import SectionCreateInput from 'components/SectionCreateInput';
import MaxFeedsStep from './MaxFeedsStep';
import FeedCreationFailedStep from './FeedCreationFailedStep';
import ConfirmFeedWithoutHistory from './ConfirmFeedWithoutHistory';

import { ReactComponent as FacebookIcon } from 'assets/icons/icon-facebook-grey.svg';
import { ReactComponent as FacebookIconActive } from 'assets/icons/icon-facebook.svg';
import WallMockup from 'assets/img/embed/wall.png';
import CarouselMockup from 'assets/img/embed/carousel.png';
import SlideshowMockup from 'assets/img/embed/slideshow.png';
import GridMockup from 'assets/img/embed/grid.png';
import InstagramIconPNG from 'assets/icons/icon-instagram-grey.png';
import InstagramIconActivePNG from 'assets/icons/icon-instagram.png';
import { ReactComponent as LinkedInIcon } from 'assets/icons/icon-linkedin-grey.svg';
import { ReactComponent as LinkedInIconActive } from 'assets/icons/icon-linkedin.svg';
import { ReactComponent as PinterestIcon } from 'assets/icons/icon-pinterest-grey.svg';
import { ReactComponent as PinterestIconActive } from 'assets/icons/icon-pinterest.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/icon-tiktok-grey.svg';
import { ReactComponent as TikTokIconActive } from 'assets/icons/icon-tiktok.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/icon-twitter-grey.svg';
import { ReactComponent as TwitterIconActive } from 'assets/icons/icon-twitter.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/icon-youtube-grey.svg';
import { ReactComponent as YouTubeIconActive } from 'assets/icons/icon-youtube.svg';
import { ReactComponent as RssIcon } from 'assets/icons/icon-rss-grey.svg';
import { ReactComponent as RssIconActive } from 'assets/icons/icon-rss.svg';
import { ReactComponent as ReviewsIcon } from 'assets/icons/icon-reviews-grey.svg';
import { ReactComponent as ReviewsIconActive } from 'assets/icons/icon-reviews.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/icon-google-grey.svg';
import { ReactComponent as GoogleIconActive } from 'assets/icons/icon-google.svg';

import styles from './Conversation.module.scss';
import { helpScoutMessage } from 'utils/help-scout';
import { postHogIdentifyUser } from "../../utils/post-hog-sync";

const EMPTY_RESPONSE = { value: null, text: null };

const SERVICES_WITHOUT_HISTORY_FETCHING = Object.freeze([
  SERVICES.TIKTOK,
  SERVICES.GOOGLE_REVIEW,
  SERVICES.LINKEDIN,
  SERVICES.TWITTER_V2,
  SERVICES.INSTAGRAM_BASIC,
]);

function conversationForSelectedFeedType(selectedFeedType) {
  switch (selectedFeedType) {
    case FEED_TYPES.FACEBOOK:
      return <ConversationAboutFacebook />;
    case FEED_TYPES.INSTAGRAM:
    case FEED_TYPES.INSTAGRAM_BASIC:
      return <ConversationAboutInstagram />;
    case FEED_TYPES.LINKEDIN:
      return <ConversationAboutLinkedin />;
    case FEED_TYPES.PINTEREST:
      return <ConversationAboutPinterest />;
    case FEED_TYPES.TIKTOK:
      return <ConversationAboutTiktok />;
    case FEED_TYPES.TWITTER:
      return <ConversationAboutTwitterV2 />;
    case FEED_TYPES.YOUTUBE:
      return <ConversationAboutYoutube />;
    case FEED_TYPES.RSS:
      return <ConversationAboutRSS />;
    case FEED_TYPES.REVIEWS:
      return <ConversationAboutReviews />;
    case FEED_TYPES.GOOGLE_REVIEW:
      return <ConversationAboutGoogleReview />;
    case null:
      return null;
    default:
      return <p>Something’s wrong. Feed type {selectedFeedType} is invalid</p>;
  }
}

function getWelcomeMessages({ feedsCreatedInSession, isFirstTimeUser, userFirstname }) {
  // Another creation round in session
  if (feedsCreatedInSession > 0) {
    return ['Where would you like me to gather content from?'];
  }

  // First round in session
  return isFirstTimeUser
    ? [
      <>
        Hey {userFirstname ? <span className="fs-block">{userFirstname}</span> : 'there'}, I’m the
        friendly Flockler bot! 👋
      </>,
      <div className={styles.columns}>
        <div className={styles.textColumn}>
          With my help, you can gather posts from various social media channels and display a feed
          in any digital service (website, mobile app, digital screen, etc.).
          <br />
          <br />
          Together we can combine multiple social media feeds, and you can display them anywhere
          with a Wall, Grid, Carousel, and Slideshow layout.
        </div>
        <div className={styles.embedsMockups}>
          <img src={WallMockup} alt="Wall layout" />
          <img src={GridMockup} alt="Grid layout" />
          <img src={CarouselMockup} alt="Carousel layout" />
          <img src={SlideshowMockup} alt="Slideshow layout" />
        </div>
      </div>,
      'Let’s get started with your most important channel first! Where would you like me to gather content from?',
    ]
    : [
      <>
        Nice to see you again <span className="fs-block">{userFirstname}</span>! 👋
      </>,
      'Where would you like me to gather content from?',
    ];
}

function getServiceSelectActions({ answerQuestion, enabledFeedTypes, setFeedType }) {
  const services = [
    {
      type: FEED_TYPES.FACEBOOK,
      name: 'Facebook',
      icon: <FacebookIcon />,
      activeIcon: <FacebookIconActive />,
    },
    {
      type: FEED_TYPES.INSTAGRAM,
      name: 'Instagram',
      icon: <img src={InstagramIconPNG} alt="Instagram" />,
      activeIcon: <img src={InstagramIconActivePNG} alt="Instagram" />,
    },
    {
      type: FEED_TYPES.TWITTER,
      name: 'X',
      icon: <TwitterIcon />,
      activeIcon: <TwitterIconActive />,
    },
    {
      type: FEED_TYPES.YOUTUBE,
      name: 'YouTube',
      icon: <YouTubeIcon />,
      activeIcon: <YouTubeIconActive />,
    },
    {
      type: FEED_TYPES.TIKTOK,
      name: 'TikTok',
      icon: <TikTokIcon />,
      activeIcon: <TikTokIconActive />,
    },
    {
      type: FEED_TYPES.PINTEREST,
      name: 'Pinterest',
      icon: <PinterestIcon />,
      activeIcon: <PinterestIconActive />,
    },
    {
      type: FEED_TYPES.LINKEDIN,
      name: 'LinkedIn',
      icon: <LinkedInIcon />,
      activeIcon: <LinkedInIconActive />,
    },
    {
      type: FEED_TYPES.RSS,
      name: 'RSS',
      icon: <RssIcon />,
      activeIcon: <RssIconActive />,
    },
    {
      type: FEED_TYPES.GOOGLE_REVIEW,
      name: 'Google Reviews',
      icon: <GoogleIcon />,
      activeIcon: <GoogleIconActive />,
    },
    {
      type: FEED_TYPES.REVIEWS,
      name: 'Reviews',
      icon: <ReviewsIcon />,
      activeIcon: <ReviewsIconActive />,
    },
  ];

  return services
    .filter(({ type }) => enabledFeedTypes[type])
    .map(service => ({
      action: () => {
        setFeedType(service.type);

        answerQuestion({
          questionId: STEPS.CHOOSE_SERVICE,
          response: { value: service.type, text: service.name },
        });
      },
      icon: service.icon,
      activeIcon: service.activeIcon || service.icon,
      id: service.type,
      name: service.name,
    }));
}

class Conversation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Save section count on initiation to state!
      // we need to save it to keep conversation conditional logic unchanged
      // if user adds sections
      initialSectionCount: props.siteSections ? props.siteSections.length : 0,
      isLoadingConversationState: false,
    };
  }

  componentDidMount() {
    const userId = this.props.userId;
    const siteId = this.props.siteId;
    if (userId && siteId) {
      postHogIdentifyUser(userId, siteId);
    }

    // Load conversation state with state query parameter e.g. ?state=reviewFormEditCancel
    const queryParams = new URLSearchParams(window.location.search);
    const state = queryParams?.get('state');
    if (state) {
      this.setState({ isLoadingConversationState: true });
      this.props.loadConversationState(state);

      // Give some time for conversation to be loaded…
      setTimeout(() => {
        this.setState({ isLoadingConversationState: false });

        // Remove state param from url
        var r = new URL(window.location.href);
        r?.searchParams?.delete('state');
        window?.history?.replaceState('', document.title, r.href);
      }, 3000);
    }
  }

  getConversationTemplate = () => {
    const {
      answers,
      answerToCreationPhase,
      answerQuestion,
      answerToSectionQuestion,
      answerToModerationQuestion,
      answerToInstagramSourceQuestion,
      answerToModerationConfirmQuestion,
      answerToLinkedinMultipleTargetInformQuestion,
      createHistoryPreview,
      createNewFeed,
      defaultSectionId,
      enabledFeedTypes,
      feedAccountName,
      feedsCreatedInSession,
      feedConfig,
      isCreatingMediaTracker,
      isFeedCreationConversationDone,
      isFeedSent,
      isMediaTrackerLimitReached,
      isUserCreatedFirstFeed,
      isOwnAccountFeed,
      maxMediaTrackersCount,
      mediaTrackerCount,
      redirectToFlocklerFeedsList,
      redirectToFlocklerManualContent,
      redirectToFlocklerInbox,
      redirectToFlocklerAfterSuccessfulFeedCreation,
      redirectToFlocklerSite,
      redirectToFlocklerSubscription,
      redirectToFlocklerBlog,
      selectedFeedType,
      selectedFeedTypeName,
      setFeedType,
      sectionId,
      siteSections,
      userFirstname,
      aiModerationEnabled,
      siteSubscriptionPlan,
      siteSubscriptionCustomPlan,
      reloadSiteSettings,
    } = this.props;

    // show max feeds step if max limit is reached, but not while creating/after last feed of quota
    if (!isCreatingMediaTracker && !isFeedCreationConversationDone && isMediaTrackerLimitReached) {
      return [
        MaxFeedsStep({
          maxMediaTrackersCount,
          mediaTrackerCount,
          redirectToFlocklerFeedsList,
          redirectToFlocklerSite,
        }),
      ];
    }

    const isSiteSectionQuestionNeeded =
      siteSections.length > 1 || this.state.initialSectionCount === 0;
    const hasDefaultSectionId = !isNil(defaultSectionId) && this.state.initialSectionCount !== 0;
    const sectionIdToRedirect = get(answerToSectionQuestion, 'value') || sectionId;
    const isModeratedFeed =
      get(answerToModerationQuestion, 'value') === 'moderation' &&
      get(answerToModerationConfirmQuestion, 'value') !== false;
    const isInstgramStoryPath =
      get(answerToInstagramSourceQuestion, 'value') === INSTAGRAMS_SOURCES.STORIES;
    const hasSeenLinkedinMultipleTargetInfo = get(
      answerToLinkedinMultipleTargetInformQuestion,
      'value'
    );
    const isInstagramBasicFeed = selectedFeedType === FEED_TYPES.INSTAGRAM_BASIC;

    const shouldSkipFirstBotMessage =
      isInstgramStoryPath || hasSeenLinkedinMultipleTargetInfo || isInstagramBasicFeed;

    return [
      {
        actions: getServiceSelectActions({ answerQuestion, enabledFeedTypes, setFeedType }),
        actionButtonType: 'serviceSelect',
        secondaryAction: {
          action: () =>
            helpScoutMessage({ subject: 'I don’t see my favourite social channel on the list.' }),
          buttonClassName: styles.secondaryActionNotLinkLike,
          name: (
            <>
              Don’t see your favourite social channel on the list?{' '}
              <span style={{ textDecoration: 'underline' }}>Tell us</span> and help us improve the
              service.
            </>
          ),
        },
        id: STEPS.CHOOSE_SERVICE,
        messages: getWelcomeMessages({
          feedsCreatedInSession,
          isFirstTimeUser: !isUserCreatedFirstFeed,
          userFirstname,
        }),
        botMessageStyle: { maxWidth: '90%' },
        botMessageContentStyle: { maxWidth: '85%' },
        forceOptionsVisible: true,
        response: EMPTY_RESPONSE,
        undoText: 'Choose a different source…',
        visible: true,
      },

      // History preview triggered as first step after service specific questions
      {
        id: STEPS.TRIGGER_HISTORY_PREVIEW,
        response: EMPTY_RESPONSE,
        visible: true,
        isBotMessageDisabled: true,
        isUserReplyDisabled: true,
        messages: [],
        onLoad: () => {
          // No history preview for TikTok and Google My Business
          const isFeedTypeWithoutHistory = SERVICES_WITHOUT_HISTORY_FETCHING.includes(
            feedConfig.service
          );

          // Set this "question" answered to prevent conversation position jumping after loading history preview
          answerQuestion({
            response: {
              value: 'history_preview_trigger',
              text: '',
            },
            questionId: STEPS.TRIGGER_HISTORY_PREVIEW,
          });

          if (isFeedTypeWithoutHistory) {
            return;
          }

          // Try history preview 2 times
          Promise.resolve(createHistoryPreview())
            .then(this.maybeCreateMediaTrackerAfterPreview)
            .catch(() =>
              Promise.resolve(createHistoryPreview())
                .then(this.maybeCreateMediaTrackerAfterPreview)
                .catch(this.maybeCreateMediaTrackerAfterPreview)
            );
        },
      },

      // Select section question if site has multiple sections
      isSiteSectionQuestionNeeded
        ? {
          id: STEPS.CHOOSE_SECTION,
          isBotAvatarHidden: shouldSkipFirstBotMessage,
          messages: shouldSkipFirstBotMessage
            ? ['Which section you would like to store the feed?']
            : ['Great! 👍', 'Which section you would like to store the feed?'],
          disableUndo: hasDefaultSectionId,
          undoText: 'Change section…',
          visible: true,

          select: !hasDefaultSectionId
            ? {
              title: 'Select Section',
              options: orderBy(siteSections, [
                section => (section.name || '').toLowerCase(),
              ]).map(section => ({
                action: () => {
                  answerQuestion({
                    response: {
                      value: section.id,
                      text: `Section "${section.name}"`,
                    },
                    questionId: STEPS.CHOOSE_SECTION,
                    nextId: STEPS.CHOOSE_MODERATION,
                  });
                },
                id: section.id,
                name: section.name,
              })),
              children: (
                <SectionCreateInput
                  noMargin={siteSections.length === 0}
                  title={
                    siteSections.length === 0 ? 'Create new section' : 'Or create new section'
                  }
                  onSubmit={sectionName =>
                    this.props.createSection(sectionName).then(section =>
                      answerQuestion({
                        response: {
                          value: section.id,
                          text: `Section "${section.name}"`,
                        },
                        questionId: STEPS.CHOOSE_SECTION,
                        nextId: STEPS.CHOOSE_MODERATION,
                      })
                    )
                  }
                  isLoading={this.props.isCreatingSection}
                />
              ),
            }
            : null,

          autoAction: hasDefaultSectionId
            ? () => {
              const section = findById(siteSections, defaultSectionId);

              if (!section) {
                console.log('section not found!');
                return;
              }

              answerQuestion({
                response: {
                  value: section.id,
                  text: `Section "${section.name}"`,
                },
                questionId: STEPS.CHOOSE_SECTION,
                nextId: STEPS.CHOOSE_MODERATION,
              });
            }
            : null,
          answerDropdownOptions:
            hasDefaultSectionId &&
            siteSections.map(section => ({
              text: section.name,
              value: section.id,
            })),

          answerDropdownAction: answerSectionId => {
            const section = findById(siteSections, answerSectionId);

            if (!section) {
              console.log('section not found!');
              return;
            }

            answerQuestion({
              response: {
                value: section.id,
                text: `Section "${section.name}"`,
              },
              questionId: STEPS.CHOOSE_SECTION,
              nextId: STEPS.CHOOSE_MODERATION,
            });
          },
        }
        : {},

      // Moderation
      isInstgramStoryPath
        ? {
          autoAction: () => {
            Promise.resolve(
              answerQuestion({
                response: {
                  value: false,
                  text: 'Display automatically',
                },
                nextId: STEPS.PREPARE_FEED_CREATION,
                questionId: STEPS.CHOOSE_MODERATION,
              })
            ).then(this.maybeCreateMediaTrackerAfterModerationQuestion);
          },
          actions: [],
          nextId: null,
          isBotMessageDisabled: true,
          isUserReplyDisabled: true,
          messages: [`👍`],
          id: STEPS.CHOOSE_MODERATION,
          response: EMPTY_RESPONSE,
          visible: !isSiteSectionQuestionNeeded,
        }
        : {
          actions: [
            {
              action: () => {
                reloadSiteSettings();
                Promise.resolve(
                  answerQuestion({
                    response: {
                      value: 'ai_moderation',
                      text: 'Moderate with Garde AI',
                    },
                    nextId: STEPS.CONFIRM_AI_MODERATION,
                    questionId: STEPS.CHOOSE_MODERATION,
                  })
                );
              },
              id: 'ai_moderation',
              name: 'Moderate with Garde AI',
            },
            {
              action: () => {
                // When creating feed from account owned by user
                if (isOwnAccountFeed) {
                  return answerQuestion({
                    response: {
                      value: 'moderation',
                      text: 'Save for moderation',
                    },
                    nextId: STEPS.CONFIRM_MODERATION,
                    questionId: STEPS.CHOOSE_MODERATION,
                  });
                }

                // When creating feed from other source than owned by user
                return Promise.resolve(
                  answerQuestion({
                    response: {
                      value: 'moderation',
                      text: 'Save for moderation',
                    },
                    nextId: STEPS.PREPARE_FEED_CREATION,
                    questionId: STEPS.CHOOSE_MODERATION,
                  })
                ).then(this.maybeCreateMediaTrackerAfterModerationQuestion);
              },
              id: 'moderate',
              name: 'Save for moderation',
            },
            {
              action: () =>
                Promise.resolve(
                  answerQuestion({
                    response: {
                      value: 'auto_publish',
                      text: 'Display automatically',
                    },
                    nextId: STEPS.PREPARE_FEED_CREATION,
                    questionId: STEPS.CHOOSE_MODERATION,
                  })
                ).then(this.maybeCreateMediaTrackerAfterModerationQuestion),
              id: 'auto_publish',
              name: 'Display automatically',
            },
          ],
          id: STEPS.CHOOSE_MODERATION,
          messages: [
            'Almost ready!',
            ...(isOwnAccountFeed
              ? [
                `Would you like to display all the new posts${feedAccountName ? ` from ${feedAccountName}` : ''
                } automatically?\n Moderate manually or with Flockler’s Garde AI.`,
              ]
              : [
                `Should I display all matching posts automatically or save them for moderation?\n Moderate manually or with Flockler’s Garde AI.`,
                'Even if you decide to display posts automatically, you can hide unwanted ones later.',
              ])
          ],
          undoText: 'Undo',
          disableUndo: true,
          visible: !isSiteSectionQuestionNeeded,
        },

      {
        actions: [
          {
            action: () =>
              Promise.resolve(
                answerQuestion({
                  response: {
                    value: 'moderation',
                    text: 'Yes, I want to moderate posts before displaying them',
                  },
                  nextId: STEPS.PREPARE_FEED_CREATION,
                  questionId: STEPS.CONFIRM_MODERATION,
                })
              ).then(this.maybeCreateMediaTrackerAfterModerationQuestion),
            id: 'confirm_moderate',
            name: 'Yes',
          },
          {
            action: () =>
              Promise.resolve(
                answerQuestion({
                  response: {
                    value: 'auto_publish',
                    text: 'No, display automatically',
                  },
                  nextId: STEPS.PREPARE_FEED_CREATION,
                  questionId: STEPS.CONFIRM_MODERATION,
                })
              ).then(this.maybeCreateMediaTrackerAfterModerationQuestion),
            id: 'auto_publish',
            name: 'No, display automatically',
          },
        ],
        id: STEPS.CONFIRM_MODERATION,
        messages: [
          `You are an admin of ${feedAccountName} - are you sure you'd like to moderate all the posts before displaying them on your website and other digital services?`,
        ],
        disableUndo: true,
      },
      {
        id: STEPS.CONFIRM_AI_MODERATION,
        messages: aiModerationEnabled && (siteSubscriptionPlan || siteSubscriptionCustomPlan)
          ? [
            `Great choice! Our AI moderation will help keep your content in line with your guidelines by automatically reviewing and filtering posts, hiding those that don't appear safe to display. AI moderation currently supports only images and text. If your feed includes videos, they will not be reviewed by AI moderation, only the text will be.`,
            `Should I enable Garde AI for content moderation?`,
          ]
          : siteSubscriptionPlan
            ? [
              'Our AI can review and moderate posts for you, ensuring content quality and compliance with your guidelines. This feature is available on our Business or higher Plan. Would you like to upgrade to access AI moderation?'
            ]
            : [
              'You’re currently on a Trial plan of Flockler that offers 25 AI moderated posts. Subscribing to Business or a higher plan will allow you to continue using Garde AI and unlock additional features. ',
              `Should I enable Garde AI for content moderation?`,
            ],
        actions: [
          {
            action: () => Promise.resolve(redirectToFlocklerSubscription()).then(() => this.revertToQuestion(STEPS.CHOOSE_MODERATION)),
            id: 'ai_moderation_upgrade_plan',
            name: 'Upgrade to Business Plan or higher',
            disabled: aiModerationEnabled,
          },
          {
            action: () => Promise.resolve(redirectToFlocklerSubscription()).then(() => this.revertToQuestion(STEPS.CHOOSE_MODERATION)),
            id: 'ai_moderation_subscribe_plan',
            name: 'Subscribe to Business Plan or higher',
            disabled: !aiModerationEnabled || (siteSubscriptionPlan || siteSubscriptionCustomPlan),
          },
          {
            action: () =>
              Promise.resolve(
                answerQuestion({
                  response: {
                    value: 'ai_moderation',
                    text: 'Yes, Start Now',
                  },
                  nextId: STEPS.PREPARE_FEED_CREATION,
                  questionId: STEPS.CONFIRM_AI_MODERATION,
                })
              ).then(this.maybeCreateMediaTrackerAfterModerationQuestion),
            id: 'ai_moderation',
            name: 'Yes, Start Now',
            disabled: !aiModerationEnabled,
          },
          {
            action: () => Promise.resolve(redirectToFlocklerBlog()),
            id: 'ai_moderation_learn_more',
            name: 'Why AI Moderation?',
          },
          {
            action: () => Promise.resolve(this.revertToQuestion(STEPS.CHOOSE_MODERATION)),
            id: 'ai_moderation_go_back',
            name: 'Go Back',
          },
        ],
        disableUndo: true,
      },

      // "Ghost" question when waiting for confirmation
      {
        id: STEPS.PREPARE_FEED_CREATION,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !!this.props.answerToPrepareFeedCreation,
        isLoading: true,
        messages: [],
      },

      // Confirm feed creation without history
      ConfirmFeedWithoutHistory({
        onRevertToStart: () => {
          // Revert to start of selected service flow
          const questionToRevert =
            answers.length > 1 ? answers[1].questionId : STEPS.CHOOSE_SERVICE;
          this.revertToQuestion(questionToRevert);

          // Clear feed "complete" status from feed
          this.props.setChannelConversationStatus(false);
        },
        onAccept: this.createMediaTracker,
        feedConfig,
      }),

      // "Ghost" question when waiting for pages to load
      {
        id: STEPS.CREATING_MEDIA_TRACKER,
        isUserReplyDisabled: true,
        // hides this when answer to CREATING_MEDIA_TRACKER is given
        isBotMessageDisabled: !!answerToCreationPhase,
        // changed to after creation question, so it's visible on top of hide history layer
        isAfterCreationQuestion: isFeedSent,
        messages: [
          ...(isFeedCreationConversationDone
            ? ['Done ✅']
            : [
              <span>
                Creating feed
                <AnimatedDots />
              </span>,
            ]),
        ],
        // Animation only on loading phase
        disableAnimation: isFeedSent,
      },

      // History found for first-time feed creator - display automatically
      {
        actions: [
          {
            action: () => {
              redirectToFlocklerAfterSuccessfulFeedCreation(sectionIdToRedirect);
            },
            id: 'select_the_layout',
            name: 'Select the layout',
          },
        ],
        id: STEPS.HISTORY_FOUND_FOR_FEED_FIRST_TIMER,
        messages: [
          `Great job ${userFirstname}! 🎉`,
          `I’ve now gathered the latest posts of your first feed for you. You can add more content and automated feeds from ${selectedFeedTypeName} or any other channel later.`,
          'The next step is to select a layout (Wall, Grid, Carousel, or Slideshow) that you can display in any website, mobile app, or digital service.',
        ],
        visible: false,
        isAfterCreationQuestion: true,
        disableAnimation: true,
      },

      // History found for first-time feed creator - to inbox
      {
        actions: [
          {
            action: () => {
              redirectToFlocklerInbox(sectionIdToRedirect);
            },
            id: 'show_my_inbox',
            name: 'Show my Inbox',
          },
        ],
        id: STEPS.HISTORY_FOUND_FOR_FEED_FIRST_TIMER_INBOX,
        messages: [
          `Great job ${userFirstname}! 🎉`,
          `I’ve now gathered the latest posts of your first feed for you. The posts have been saved to Inbox and are waiting for your approval. You can add more content and automated feeds from ${selectedFeedTypeName} or any other channel later.`,
          `The next step is to select some of the posts that you’d like to display.`,
        ],
        visible: false,
        isAfterCreationQuestion: true,
        disableAnimation: true,
      },

      // Successful feed for non-first timer
      {
        actions: [
          {
            action: () => createNewFeed(),
            id: 'create_another_feed',
            name: 'Yes, create another feed',
          },
          {
            action: () => {
              if (isModeratedFeed) {
                redirectToFlocklerInbox(sectionIdToRedirect);
              } else {
                redirectToFlocklerAfterSuccessfulFeedCreation(sectionIdToRedirect);
              }
            },
            id: 'back_to_flockler_app',
            name: 'No, show me the content',
          },
        ],
        id: STEPS.HISTORY_FOUND_FOR_FEED,
        messages: [
          `Awesome, your ${selectedFeedTypeName || ''}${isInstgramStoryPath ? ' Stories' : ''
          } feed is ready! 🎉`,
          'Would you like to set up another automated feed?',
        ],
        visible: false,
        isAfterCreationQuestion: true,
        disableAnimation: true,
      },

      // Show add new feed prompt question, if we did not get any history
      // This is UX decision because we don't want to redirect user
      // to empty wall in flockler-app
      {
        actions: [
          {
            action: () => createNewFeed(),
            id: 'create_another_feed',
            name: 'Yes, create another feed',
          },
          {
            action: () => redirectToFlocklerManualContent(sectionIdToRedirect),
            id: 'back_to_flockler_app',
            name: 'No, take me to the app',
          },
        ],
        id: STEPS.NO_HISTORY_FOR_FEED,
        messages: [
          isInstgramStoryPath ? (
            <>
              It looks like {feedAccountName || 'your account'} doesn't have any Stories published
              in the past 24 hours. We will gather and display all the future Stories of{' '}
              {feedAccountName || 'your account'} 👍
            </>
          ) : (
            `Awesome, we'll start to collect posts from ${selectedFeedTypeName}! 🎉`
          ),
          'Would you like to set up another automated feed?',
        ],
        visible: false,
        isAfterCreationQuestion: true,
        disableAnimation: true,
      },

      FeedCreationFailedStep({
        redirectToFlocklerSite,
        error: answerToCreationPhase,
        isLoading: isCreatingMediaTracker,
        onRetry: this.createMediaTracker,
      }),
    ];
  };

  maybeCreateMediaTrackerAfterModerationQuestion = () => {
    // if preview is ready, let's start feed creation phase
    if (!this.props.isLoadingPreview) {
      this.confirmCreatingMediaTracker();
    }
  };

  maybeCreateMediaTrackerAfterPreview = () => {
    const { isOwnAccountFeed, answerToModerationConfirmQuestion, answerToModerationQuestion } =
      this.props;

    if (!answerToModerationQuestion) {
      return;
    }
    const isModerating = answerToModerationQuestion && answerToModerationQuestion.value === 'moderation';
    const isAiModerating = answerToModerationQuestion && answerToModerationQuestion.value === 'ai_moderation';

    const hasRejectedModerationOnConfirm =
      isModerating && isAiModerating && answerToModerationConfirmQuestion && !answerToModerationConfirmQuestion.value;

    // This means that history preview was not ready when answered to moderation question
    if (!isOwnAccountFeed || !isModerating || hasRejectedModerationOnConfirm || !isAiModerating) {
      this.confirmCreatingMediaTracker();
    }
  };

  confirmCreatingMediaTracker = () => {
    const { answerQuestion, answerToInstagramSourceQuestion, previewItemsCount, feedConfig } =
      this.props;
    const isInstgramStoryPath =
      get(answerToInstagramSourceQuestion, 'value') === INSTAGRAMS_SOURCES.STORIES;
    const isFeedTypeWithoutHistory = SERVICES_WITHOUT_HISTORY_FETCHING.includes(feedConfig.service);

    // Create feed with history without confirmation
    if (isInstgramStoryPath || isFeedTypeWithoutHistory || previewItemsCount !== 0) {
      return Promise.resolve(
        answerQuestion({
          response: {
            value: true,
            text: 'No confirmation needed',
          },
          nextId: STEPS.CREATING_MEDIA_TRACKER,
          questionId: STEPS.PREPARE_FEED_CREATION,
        })
      ).then(this.createMediaTracker);
    }

    // Confirm feed creation with empty history (except story feed)
    return answerQuestion({
      response: {
        value: true,
        text: 'Need confirmation',
      },
      nextId: STEPS.CONFIRM_FEED_WITHOUT_HISTORY,
      questionId: STEPS.PREPARE_FEED_CREATION,
    });
  };

  createMediaTracker = () => {
    const {
      answerQuestion,
      answerToModerationQuestion,
      createMediaTracker,
      isUserCreatedFirstFeed,
      previewItemsCount,
      feedConfig,
    } = this.props;

    Promise.resolve(createMediaTracker())
      .then(() => {
        const isFeedTypeWithoutHistory = SERVICES_WITHOUT_HISTORY_FETCHING.includes(
          feedConfig.service
        );
        const hasFoundHistoryPosts = previewItemsCount > 0;

        const shouldShowHistoryFoundPath = hasFoundHistoryPosts || isFeedTypeWithoutHistory;

        // First timer with successful feed with history
        if (shouldShowHistoryFoundPath && !isUserCreatedFirstFeed) {
          const isModerating = answerToModerationQuestion && answerToModerationQuestion.value === 'moderation';

          return answerQuestion({
            response: {
              value: true,
              text: 'Media tracker is ready',
            },
            nextId: isModerating
              ? STEPS.HISTORY_FOUND_FOR_FEED_FIRST_TIMER_INBOX
              : STEPS.HISTORY_FOUND_FOR_FEED_FIRST_TIMER,
            questionId: STEPS.CREATING_MEDIA_TRACKER,
          });
        }

        // Non-first timer with successful feed with history
        if (shouldShowHistoryFoundPath && isUserCreatedFirstFeed) {
          return answerQuestion({
            response: {
              value: true,
              text: 'Media tracker is ready',
            },
            nextId: STEPS.HISTORY_FOUND_FOR_FEED,
            questionId: STEPS.CREATING_MEDIA_TRACKER,
          });
        }

        // No history found
        answerQuestion({
          response: {
            value: true,
            text: 'Media tracker is ready',
          },
          nextId: STEPS.NO_HISTORY_FOR_FEED,
          questionId: STEPS.CREATING_MEDIA_TRACKER,
        });
      })
      .catch(error => {
        answerQuestion({
          response: {
            value: error || { status: null },
            text: 'Media tracker creation failed',
          },
          nextId: STEPS.ERROR_CREATING_FEED,
          questionId: STEPS.CREATING_MEDIA_TRACKER,
        });
      });
  };

  conversationBottomRef = React.createRef();

  revertToQuestion(questionId) {
    this.props.revertToQuestion(questionId);

    if (questionId === STEPS.CHOOSE_SERVICE) {
      this.props.setAccountId(null);
      this.props.setFeedType(null);
      this.props.setChannelConversationStatus(false);
    }
  }

  renderMainConversation(conversationState, selectedFeedType, isChannelConversationDone) {
    return (
      <React.Fragment>
        {/* 1. First general question */}
        <Question
          question={conversationState[0]}
          revertToQuestion={() => this.revertToQuestion(conversationState[0].id)}
        />

        {/* 2. Channel specific questions */}
        {conversationForSelectedFeedType(selectedFeedType)}

        {/* 3. Rest of general questions */}
        {isChannelConversationDone &&
          conversationState
            .slice(1)
            .filter(question => question.visible && !question.isAfterCreationQuestion)
            .map(question => (
              <Question
                key={question.id}
                question={question}
                revertToQuestion={() => this.revertToQuestion(question.id)}
              />
            ))}
      </React.Fragment>
    );
  }

  renderAfterConversation(conversationState) {
    return conversationState
      .slice(1)
      .filter(question => question.visible && question.isAfterCreationQuestion)
      .map(question => (
        <Question
          key={question.id}
          question={question}
          revertToQuestion={() => this.revertToQuestion(question.id)}
        />
      ));
  }

  render() {
    const {
      answers,
      isChannelConversationDone,
      isRefreshingConversation,
      isFeedSent,
      selectedFeedType,
    } = this.props;

    const conversationTemplate = this.getConversationTemplate();
    const conversationState = getConversationState(conversationTemplate, answers);

    if (isRefreshingConversation) {
      return null; // Loader?
    }

    return (
      <div
        className={classnames(styles.conversation, {
          [styles.dodgeMessenger]: this.props.isMessengerOpen,
        })}
      >
        {this.state.isLoadingConversationState && <div className={styles.stateLoadWaiter}></div>}

        <div className={styles.conversationWrapper}>
          <div
            className={classnames(styles.conversationPart, {
              [styles.pastConversation]: isFeedSent,
            })}
          >
            {this.renderMainConversation(
              conversationState,
              selectedFeedType,
              isChannelConversationDone
            )}
          </div>
          {isFeedSent && this.renderAfterConversation(conversationState)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  answers: getAnswers,
  answerToModerationQuestion: getQuestionAnswer(STEPS.CHOOSE_MODERATION),
  answerToModerationConfirmQuestion: getQuestionAnswer(STEPS.CONFIRM_MODERATION),
  answerToSectionQuestion: getQuestionAnswer(STEPS.CHOOSE_SECTION),
  answerToCreationPhase: getQuestionAnswer(STEPS.CREATING_MEDIA_TRACKER),
  answerToPrepareFeedCreation: getQuestionAnswer(STEPS.PREPARE_FEED_CREATION),
  answerToInstagramSourceQuestion: getQuestionAnswer(INSTAGRAM_STEPS.WHAT_TYPE_OF_MEDIA_TO_COLLECT),
  answerToLinkedinMultipleTargetInformQuestion: getQuestionAnswer(
    LINKEDIN_STEPS.INFORM_ABOUT_TARGET_FILTERING
  ),
  defaultSectionId: getRouteParamSectionId,
  enabledFeedTypes: getEnabledFeedTypes,
  feedAccountName: getFeedAccountName,
  feedConfig: getFeedConfig,
  feedsCreatedInSession: getSessionCreatedFeedsCount,
  isChannelConversationDone: getChannelConversationStatus,
  isCreatingMediaTracker: getMediaTrackerCreationStatus,
  isLoadingPreview: getHistoryPreviewLoadingState,
  isMediaTrackerLimitReached: getMediaTrackersLimitReached,
  isMessengerOpen: getMessengerOpenState,
  isRefreshingConversation: getConversationRefreshStatus,
  isFeedCreationConversationDone: getFeedCreationConversationStatus,
  isUserCreatedFirstFeed: getUserHasCreatedFirstFeed,
  isCreatingSection: getSectionCreationStatus,
  isFeedSent: getFeedSentStatus,
  isOwnAccountFeed: getFeedOwnAccountInfo,
  maxMediaTrackersCount: getSiteMaxMediaTrackers,
  mediaTrackerCount: getCurrentFeedCount,
  previewItemsCount: getPreviewItemsCount,
  sectionId: getSectionId,
  selectedFeedType: getConversationFeedType,
  selectedFeedTypeName: getSelectedFeedTypeName,
  siteSections: getSiteSections,
  siteUrl: getSiteUrl,
  userFirstname: getUserFirstname,
  userId: getUserId,
  siteId: getSiteId,
  aiModerationEnabled: getSiteAiModerationEnabled,
  siteSubscriptionPlan: getSiteSubscriptionPlan,
  siteSubscriptionCustomPlan: getSiteSubscriptionCustomPlan,
});

const mapDispatchToProps = {
  answerQuestion,
  createMediaTracker,
  createNewFeed,
  createHistoryPreview,
  createSection,
  loadConversationState,
  revertToQuestion,
  redirectToFlocklerAfterSuccessfulFeedCreation,
  redirectToFlocklerFeedsList,
  redirectToFlocklerInbox,
  redirectToFlocklerSection,
  redirectToFlocklerSite,
  redirectToFlocklerSubscription,
  redirectToFlocklerBlog,
  setAccountId,
  redirectToFlocklerManualContent,
  setFeedType,
  setChannelConversationStatus,
  reloadSiteSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
