import PropTypes from 'prop-types';
import WebComponent from 'utils/web-component';
import { useEffect, useState, React } from 'react';

import { helpScoutMessage } from 'utils/help-scout';
import styles from './Header.module.scss';

const Header = ({ continueToAppUrl, children, ...rest }) => {
  const [narrowScreen, setNarrowScreen] = useState(false);

  const isNarrowScreen = () => {
    return typeof window !== 'undefined' ? window.innerWidth < 1024 : false;
  };

  useEffect(() => {
    const handleResize = () => {
      setNarrowScreen(isNarrowScreen());
    };

    setNarrowScreen(isNarrowScreen());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.header} {...rest}>
      <div className={styles.headerContent}>
        <div className={styles.actionLinkHelpWCContainer}>
          <WebComponent
            tag="fl-button"
            variant="tetriary"
            class={`transparent ${narrowScreen ? 'tight' : ''}`}
            size={narrowScreen ? 'small' : 'medium'}
            onClick={() => helpScoutMessage({ subject: `Help with automated feed.` })}
            title="Help"
            aria-label="Help"
          >
            <span>Help</span>
          </WebComponent>
        </div>

        <div className={styles.headerTitle}>{children}</div>

        {continueToAppUrl ? (
          <div className={styles.actionLinkWCContainer}>
            <WebComponent
              tag='fl-button'
              href={continueToAppUrl}
              variant="secondary"
              class={narrowScreen ? 'tight nowrap' : 'nowrap'}
              size={narrowScreen ? 'small' : 'medium'}
              title="Continue to App"
              aria-label="Continue to App"
              responsive="treu"
            >
              <span>{narrowScreen ? '' : 'Continue to'} App &rarr;</span>
            </WebComponent>
          </div>
        ) : (
          <div className={styles.actionLinkWCContainer}>
            <div className={styles.actionLinkPlaceholder}></div>
          </div>
          )
        }
      </div>
    </div>
  );
}

Header.defaultProps = {
  continueToAppUrl: null,
  children: null,
};

Header.propTypes = {
  continueToAppUrl: PropTypes.string,
  clildren: PropTypes.node,
};

export default Header;
