import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ActionButton from 'components/ActionButton';
import styles from './UserMessageActions.module.scss';

const UserMessageActions = React.memo(({ actions, actionButtonsDirection }) => {
  return (
    <div className={styles[actionButtonsDirection]}>
      {actions.filter(option => !option.disabled).map(option => {
        const hasIcon = !!option.icon;
        return (
          <ActionButton
            key={option.id}
            onClick={option.action}
            className={`nowrap ml mt tight ${hasIcon ? 'image' : ''}`}
            style={option.style}
            disabled={option.disabled}
            useWebcomponent={true}
          >
            {hasIcon && <span className={styles.icon}>{option.icon}</span>}
            {option.name}
            {option.label ? <span className={styles.actionButtonLabel}>{option.label}</span> : ''}
          </ActionButton>
        );
      })}
    </div>
  );
});

UserMessageActions.defaultProps = {
  actionButtonsDirection: 'row',
  actions: [],
};

UserMessageActions.propTypes = {
  actionButtonsDirection: PropTypes.string,
  actions: PropTypes.array.isRequired,
};

export default UserMessageActions;
