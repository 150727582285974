import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ActionButton.module.scss';
import WebComponent from 'utils/web-component';

export const actionButtonize = WrappedComponent => {
  return ({ children, className, isSelected, isInactive, disabled, useWebcomponent = false, ...rest }) => (
    useWebcomponent ? (
      <WebComponent
        tag="fl-button"
        class={className ? className : "ml nowrap"}
        variant={isSelected ? 'success' : isInactive ? 'disabled' : 'primary'}
        disabled={disabled}
        {...rest}
      >
        {children}
      </WebComponent>
    ) : (
      <WrappedComponent
        className={classnames(
          styles.actionButton,
          {
            [styles.selected]: isSelected,
            [styles.inactive]: isInactive,
            [styles.disabled]: disabled,
          },
          className
        )}
        {...rest}
      >
        {children}
      </WrappedComponent>
    )
  )
}

const ActionButton = actionButtonize('button');

ActionButton.defaultProps = {
  children: undefined,
  isSelected: false,
};

ActionButton.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
};

export default React.memo(ActionButton);
