import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import config from 'config';
import { getConversationState } from 'services/conversation-updater';
import { setFeedType } from 'concepts/feed-builder';
import { redirectToFlocklerEmbed } from 'concepts/redirect-flockler';
import { SERVICES } from 'constants/Services';
import { getSiteArticlesCount, getSite, getSiteSections } from 'concepts/site';
import { getUserFirstname } from 'concepts/user';
import { STEPS } from 'services/media-trackers/common';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import {
  createDemoReviews,
  acceptReviewsDpa,
  fetchReviewsDpa,
  fetchReviewsService,
  sendReviewFormEmbedCode,
} from 'services/api';
import { embedCode, DEFAULT_FORM_CONFIG } from 'services/reviews';

import styles from './ConversationAboutReviews.module.scss';
import WallMockup from 'assets/img/reviews/wall.jpg';
import CarouselMockup from 'assets/img/reviews/carousel.jpg';
import SlideshowMockup from 'assets/img/reviews/slideshow.jpg';
import GridMockup from 'assets/img/reviews/grid.jpg';
import ReviewFormImage from 'assets/img/reviews/review-form.png';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/reviews';
import WebComponent from 'utils/web-component';

const fetchReviewForms = ({ siteUuid, apiBaseUrl, token }) => {
  return fetch(`${apiBaseUrl}/${siteUuid}/forms`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(res => res.json());
};

const ConversationAboutReviews = ({
  answers,
  articlesCount,
  setFeedType,
  redirectToFlocklerEmbed,
  revertToQuestion,
  answerQuestion,
  sections,
  site,
  userFirstName,
}) => {
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState(false);
  const [reviewsServiceData, setReviewsServiceData] = useState(undefined);
  const [reviewForms, setReviewForms] = useState(undefined);
  const [isEmbedCodeSent, setIsEmbedCodeSent] = useState(false);
  const [isLoadingReviewForms, setIsLoadingReviewForms] = useState(false);
  const [hasLoadedConversationState, setHasLoadedConversationState] = useState(false);

  const conversationStateParam = new URLSearchParams(window.location.search)?.get('state');
  const isFirstUserExperience = !articlesCount;
  const siteUrl = site?.site_url;
  const siteId = site?.id;

  useEffect(() => {
    if (conversationStateParam) setHasLoadedConversationState(true);
  }, [conversationStateParam]);

  const conversation = [
    {
      onLoad: () => {
        fetchReviewsService(site.id).then(response => {
          setIsLoadingReviewForms(true);
          const reviewsServiceResponseData = response?.data?.reviewsService;
          setReviewsServiceData(reviewsServiceResponseData);

          if (conversationStateParam) {
            setIsLoadingReviewForms(false);
            return;
          }

          fetchReviewForms({
            siteUuid: site.uuid,
            apiBaseUrl: reviewsServiceResponseData.apiBaseUrl,
            token: reviewsServiceResponseData.token,
          })
            .then(response => {
              setIsLoadingReviewForms(false);
              setReviewForms(response?.reviewForms);

              if (!response?.reviewForms?.length) {
                answerQuestion({
                  response: {
                    value: 'noForms',
                    text: 'No form created yet',
                  },
                  questionId: QUESTION.INITIAL_CHECK_REVIEW_FORMS,
                  nextId: QUESTION.INTRODUCTION,
                });
              } else {
                answerQuestion({
                  response: {
                    value: 'hasForms',
                    text: 'Form already created',
                  },
                  questionId: QUESTION.INITIAL_CHECK_REVIEW_FORMS,
                  nextId: QUESTION.ALREADY_HAS_REVIEW_FORM,
                });
              }
            })
            .catch(() => {
              setIsLoadingReviewForms(false);
              answerQuestion({
                response: {
                  value: 'noForms',
                  text: 'No forms',
                },
                questionId: QUESTION.INITIAL_CHECK_REVIEW_FORMS,
                nextId: QUESTION.INTRODUCTION,
              });
            });
        });
      },

      isBotMessageDisabled: !isLoadingReviewForms,
      isUserReplyDisabled: true,
      isLoading: true, // shows just loader
      messages: [],
      id: QUESTION.INITIAL_CHECK_REVIEW_FORMS,
      visible: !hasLoadedConversationState,
    },

    /* # Already has review forms */
    {
      actions: [
        {
          action: () => {
            window.location.href = `${config.flocklerAppUrl}/${siteUrl}/reviews`;

            answerQuestion({
              response: {
                value: 'yes',
                text: 'Show Reviews tab',
              },
              questionId: QUESTION.ALREADY_HAS_REVIEW_FORM,
              nextId: QUESTION.TRANSITION_TO_APP,
            });
          },
          id: 1,
          name: 'Show Reviews tab',
        },
        {
          action: () => {
            setFeedType(null);
            revertToQuestion(STEPS.CHOOSE_SERVICE);
          },
          id: 2,
          name: 'Gather something else',
        },
      ],
      messages: [
        "Your team has previously created a form to gather reviews and testimonials. If you’d like to edit the form or create a new one, navigate to the 'Reviews' tab.",
      ],
      id: QUESTION.ALREADY_HAS_REVIEW_FORM,
      visible: false,
    },

    /* # No review forms yet */
    {
      actions: [
        {
          action: () => {
            if (!isFirstUserExperience) {
              answerQuestion({
                response: {
                  value: 'yes',
                  text: 'Yes, I’d like to start!',
                },
                questionId: QUESTION.INTRODUCTION,
                nextId: QUESTION.TRANSITION_TO_APP,
              });

              window.location.href = `${config.flocklerAppUrl}/${siteUrl}/reviews`;
              return;
            }

            answerQuestion({
              response: {
                value: 'yes',
                text: 'Yes, I’d like to start!',
              },
              questionId: QUESTION.INTRODUCTION,
              nextId: QUESTION.CONFIRM_DPA,
            });
          },
          id: 1,
          name: 'Yes, I’d like to start!',
        },
        {
          action: () => {
            setFeedType(null);
            revertToQuestion(STEPS.CHOOSE_SERVICE);
          },
          id: 2,
          name: 'No, take me back',
        },
      ],
      messages: [
        'With Flockler, you can gather reviews and testimonials, including star ratings, text, and images. After collecting, you can show reviews separately or combine them with social media feeds.',
        'Would you like to start gathering customer reviews and testimonials?',
      ],
      id: QUESTION.INTRODUCTION,
      visible: false,
    },
    {
      actions: [
        {
          action: () => {
            fetchReviewsDpa(siteId)
              .then(response => {
                // Not accepted yet
                const dpas = response?.data?.data || {};
                const dpaAgreed = dpas?.siteId === siteId;
                if (!dpaAgreed) return acceptReviewsDpa(siteId);
                return Promise.resolve();
              })
              .catch(e => {
                // Not accepted yet
                return acceptReviewsDpa(siteId);
              })
              .then(() => {
                // Articles count should be 0 here, but for testing phase iffing here…
                if (!articlesCount) return createDemoReviews(siteId);
                return Promise.resolve();
              })
              .then(() => {
                answerQuestion({
                  response: {
                    value: 'yes',
                    text: 'Yes, I accept',
                  },
                  questionId: QUESTION.CONFIRM_DPA,
                  nextId: QUESTION.CREATE_DEMO_REVIEWS,
                });
              });
          },
          id: 3,
          name: 'Yes, I accept',
        },
        {
          action: () => {
            setFeedType(null);
            revertToQuestion(STEPS.CHOOSE_SERVICE);
          },
          id: 4,
          name: 'No, take me back',
        },
      ],
      messages: [
        <>
          Fantastic! When collecting reviews from your customers, your form will typically include a
          name and email address; we’ll keep them stored for you. To do that, we will need you to
          accept our{' '}
          <a href="https://www.relaycommerce.io/dpa" target="_blank" rel="noreferrer">
            data processing agreement
          </a>
          .
        </>,
        'Could you confirm that you’ve read the agreement and that you accept the terms?',
      ],
      // disableUndo: true,
      id: QUESTION.CONFIRM_DPA,
    },
    {
      actions: [
        {
          action: () => {
            fetchReviewForms({
              siteUuid: site.uuid,
              apiBaseUrl: reviewsServiceData.apiBaseUrl,
              token: reviewsServiceData.token,
            })
              .then(response => {
                if (response?.reviewForms?.length) return Promise.resolve();

                const formData = {
                  ...DEFAULT_FORM_CONFIG,
                  siteUuid: site.uuid,
                  name: 'Reviews',
                  sectionUuid: sections[sections.length - 1].uuid,
                };

                // Create first review form
                return fetch(`${reviewsServiceData.apiBaseUrl}/${site.uuid}/forms`, {
                  headers: {
                    Authorization: `Bearer ${reviewsServiceData.token}`,
                  },
                  method: 'POST',
                  body: JSON.stringify(formData),
                });
              })
              .then(() => {
                answerQuestion({
                  response: {
                    value: 'great',
                    text: 'Great, let’s create a form!',
                  },
                  questionId: QUESTION.CREATE_DEMO_REVIEWS,
                  nextId: QUESTION.CREATE_REVIEW_FORM,
                });
              });
          },
          id: 5,
          name: 'Great, let’s create a form!',
        },
        {
          action: () => {
            setFeedType(null);
            revertToQuestion(STEPS.CHOOSE_SERVICE);
          },
          id: 6,
          name: 'No, take me back',
        },
      ],

      botMessageStyle: { maxWidth: '85%' },
      botMessageContentStyle: { maxWidth: '80%' },
      messages: [
        'Thanks for confirming!',
        <div className={styles.columns}>
          <div className={styles.textColumn}>
            We have now added five <strong>example reviews</strong> to your Flockler and you can
            hide them once you’ve gathered reviews yourself.
            <span className={styles.hideMobile}>
              <br />
              <br />
              Here’s how they look on our Wall, Grid, Carousel, and Slideshow layouts.
            </span>
          </div>
          <div className={styles.embedsMockups}>
            <img src={WallMockup} alt="Reviews in Wall layout" />
            <img src={GridMockup} alt="Reviews in Grid layout" />
            <img src={CarouselMockup} alt="Reviews in Carousel layout" />
            <img src={SlideshowMockup} alt="Reviews in Slideshow layout" />
          </div>
        </div>,
        'Next step is to create a form to gather reviews on any website and app.',
      ],
      // disableUndo: true,
      visible: false,
      id: QUESTION.CREATE_DEMO_REVIEWS,
    },

    {
      actions: [
        {
          id: 7,
          name: 'The form looks great!',
          action: () => {
            answerQuestion({
              response: {
                value: 'great',
                text: 'The form looks great!',
              },
              questionId: QUESTION.CREATE_REVIEW_FORM,
              nextId: QUESTION.EMBED_REVIEW_FORM,
            });
          },
        },
        {
          id: 8,
          name: 'I’d like to edit the form',
          action: () => {
            answerQuestion({
              response: {
                value: 'edit',
                text: 'I’d like to edit the form',
              },
              questionId: QUESTION.CREATE_REVIEW_FORM,
              nextId: QUESTION.TRANSITION_TO_APP,
            });

            fetchReviewForms({
              siteUuid: site.uuid,
              apiBaseUrl: reviewsServiceData.apiBaseUrl,
              token: reviewsServiceData.token,
            }).then(response => {
              const formUuid = response?.reviewForms[0]?.uuid;
              let pathToFormEdit = formUuid
                ? `${config.flocklerAppUrl}/${siteUrl}/reviews/${response.reviewForms[0].uuid}/edit?firstTimeUserExperience=1`
                : `${config.flocklerAppUrl}/${siteUrl}/reviews`;
              window.location.href = pathToFormEdit;
            });
          },
        },
      ],
      messages: [
        <div>
          <div className={styles.reviewFormPreviewText}>
            Here we go! You can embed the form on any website, and here’s a preview:
          </div>
          <div className={styles.reviewFormPreview}>
            <img src={ReviewFormImage} alt="Reviews form" />
          </div>
        </div>,
      ],
      id: QUESTION.CREATE_REVIEW_FORM,
    },

    {
      onLoad: () => {
        fetchReviewForms({
          siteUuid: site.uuid,
          apiBaseUrl: reviewsServiceData.apiBaseUrl,
          token: reviewsServiceData.token,
        }).then(response => {
          setReviewForms(response?.reviewForms);
        });
      },
      actions: [
        {
          id: 9,
          name: 'I’ve embedded it!',
          action: () => {
            answerQuestion({
              response: {
                value: 'embedded',
                text: 'I’ve embedded it!',
              },
              questionId: QUESTION.EMBED_REVIEW_FORM,
              nextId: QUESTION.WHAT_TO_DO_NEXT,
            });
          },
        },
        {
          id: 10,
          name: 'I’ll do it later',
          action: () => {
            answerQuestion({
              response: {
                value: 'later',
                text: 'I’ll do it later',
              },
              questionId: QUESTION.EMBED_REVIEW_FORM,
              nextId: QUESTION.WHAT_TO_DO_NEXT,
            });
          },
        },
      ],
      messages: [
        <>
          <div>
            The next step is to embed the form on your website. Here’s the embed code for you:
            <div className={styles.reviewFormEmbed}>
              <pre>
                {reviewForms?.[0]
                  ? embedCode({ siteUuid: site.uuid, reviewFormUuid: reviewForms[0]?.uuid })
                  : null}
              </pre>
              <div className={styles.reviewFormEmbedCopy}>
                <WebComponent
                  tag="fl-button"
                  size="small"
                  variant={isCopiedMessageVisible ? 'success' : 'primary'}
                  disabled={!reviewForms?.[0]}
                  onClick={() => {
                    setCopiedMessageVisible(true);
                    navigator.clipboard
                      .writeText(
                        embedCode({
                          siteUuid: site.uuid,
                          reviewFormUuid: reviewForms[0].uuid,
                        })?.trim()
                      )
                      .then(
                        function () {
                          setTimeout(() => {
                            setCopiedMessageVisible(false);
                          }, 1500);
                        },
                        function () {
                          setCopiedMessageVisible(false);
                        }
                      );
                  }}
                >
                  {isCopiedMessageVisible ? 'Copied!' : 'Copy embed code'}
                </WebComponent>
              </div>
            </div>
          </div>
        </>,
      ],
      id: QUESTION.EMBED_REVIEW_FORM,
    },

    {
      onLoad: () => {
        // Send review form embed code via email, but only once
        if (!isEmbedCodeSent && reviewForms?.length) {
          sendReviewFormEmbedCode(site.id).then(() => {
            setIsEmbedCodeSent(true);
          });
        }
      },

      actions: [
        {
          id: 11,
          name: 'Choose a layout to show reviews',
          action: () => {
            answerQuestion({
              response: {
                value: 'layouts',
                text: 'Choose a layout to show review',
              },
              questionId: QUESTION.WHAT_TO_DO_NEXT,
              nextId: QUESTION.TRANSITION_TO_APP,
            });

            redirectToFlocklerEmbed();
          },
        },
        {
          id: 12,
          name: 'Add content from social media channels',
          action: () => {
            answerQuestion({
              response: {
                value: 'addContent',
                text: 'Add content from social media channels',
              },
              questionId: QUESTION.WHAT_TO_DO_NEXT,
              nextId: QUESTION.TRANSITION_TO_APP,
            });
            setFeedType(null);
            revertToQuestion(STEPS.CHOOSE_SERVICE);
          },
        },
      ],
      // TODO how to send mail?
      messages: [
        `Great job ${userFirstName}! We’ve sent the form’s embed code to your email address just now, and you can also find the embed on Flockler’s Reviews tab later.`,
        'What would you like to do next?',
      ],
      id: QUESTION.WHAT_TO_DO_NEXT,
    },

    {
      isUserReplyDisabled: true,
      isLoading: true, // shows just loader
      messages: [],
      id: QUESTION.TRANSITION_TO_APP,
    },
  ];

  const conversationState = getConversationState(conversation, answers);

  return (
    <ConversationMessageList conversation={conversationState} revertToQuestion={revertToQuestion} />
  );
};

const mapStateToProps = state => ({
  articlesCount: getSiteArticlesCount(state),
  site: getSite(state),
  sections: getSiteSections(state),
  userFirstName: getUserFirstname(state),
});

const mapDispatchToProps = {
  redirectToFlocklerEmbed,
  setFeedType,
};

const ConnectedConversationAboutReviews = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutReviews);

export default serviceConversation(ConnectedConversationAboutReviews, {
  service: SERVICES.REVIEWS,
});
